<template>
  <div class="wrapper d-flex">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <input
          :disabled="accessEnable"
          v-bind="attrs"
          v-on="on"
          :value="value"
          type="range"
          name="points"
          min="1"
          step="1"
          @change="filterme"
          :class="
            value === 1 && !accessEnable
              ? 'custom-toggleDisable'
              : value === 2 && !accessEnable
              ? 'custom-toggleEnable'
              : 'custom-disable'
          "
          max="2"
        />
      </template>
      <span>{{ status }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 2,
      classType: "tgl-def",
    };
  },
  props: {
    roleAccess: {
      type: Object,
      require: true,
    },
    accessEnable: {
      type: Boolean,
    },
  },
  mounted() {
    // this.value = this.roleAccess.accessModeId;
    this.checkAndChangeValue(this.roleAccess.accessModeId);
  },
  watch: {
    roleAccess: {
      handler: function (newVal) {
        this.checkAndChangeValue(newVal.accessModeId);
      },
      deep: true,
    },
  },
  computed: {
    status() {
      return this.value === 1 ? "Disable" : "Enable";
    },
  },

  methods: {
    filterme(event) {
      const value = parseInt(event.target.value, 10); // Convert to an integer
      if (value === 1) {
        this.value = value;
        this.$emit("changeAccess", {
          actionId: this.roleAccess.actionId,
          childSection: this.roleAccess.childSection,
          parentSection: this.roleAccess.parentSection,
          accessModeId: 0,
          isChecked: false,
        });
      } else if (value === 2) {
        this.value = value;
        this.$emit("changeAccess", {
          actionId: this.roleAccess.actionId,
          childSection: this.roleAccess.childSection,
          parentSection: this.roleAccess.parentSection,
          accessModeId: 1,
          isChecked: true,
        });
      }
    },
    checkAndChangeValue(value) {
      if (value === 1) {
        this.value = 2;
      } else if (value === 0) {
        this.value = 1;
      }
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 70px;
  height: -7%;
  display: flex;
  justify-content: center;
  padding: 0px 0px;
}

.custom-disable {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  border: 1px solid transparent;
  background-color: rgb(153, 153, 147);
  width: 60px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 0 2px;
  margin: 0;
  cursor: pointer;
}

.custom-toggle::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #fff;
  appearance: none;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  border: white 1px solid;
}
.custom-toggle:focus {
  outline: none;
}

.custom-toggleDisable {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  border: 1px solid transparent;

  background-color: rgb(241, 82, 82);
  width: 60px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 0 2px;
  margin: 0;
  cursor: pointer;
}

.custom-toggleDisable::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #fff;
  appearance: none;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  border: white 1px solid;
}

.custom-toggleEnable {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  border: 1px solid transparent;

  background-color: rgb(105, 229, 105);
  width: 60px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  padding: 0 2px;
  margin: 0;
  cursor: pointer;
}

.custom-toggleEnable::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #fff;
  appearance: none;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  border: white 1px solid;
}
</style>
