<template>
  <master-layout>
    <v-card class="px-3 py-5">
      <h3>Update User Access</h3>
      <v-row class="my-0">
        <v-col cols="12" sm="10" class="">
          <v-select
            v-model="rolename"
            :items="roles"
            item-text="roleName"
            item-value="roleId"
            label="Roles"
            outlined
            prepend-icon="mdi-account-box-multiple-outline"
            @change="getUserById"
          ></v-select>
          <v-select
            v-model="userId"
            :items="userType"
            item-text="userName"
            item-value="userId"
            label="Select User"
            outlined
            prepend-icon="mdi-account-box"
            @change="getrolesList"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12">
          <div class="d-flex roles-access-list">
            <h4 v-if="roleList.length !== 0">Access List</h4>

            <template>
              <v-card class="vCardClass" elevation="0" width="100%">
                <v-list
                  class="vListClass"
                  v-for="(roles, index) in roleList"
                  :key="index"
                >
                  <v-list-item-content>
                    <template v-for="(subRole, ind) in roles">
                      <v-list-item-title
                        class="d-flex"
                        :key="ind"
                        v-if="ind === 0"
                      >
                        <v-checkbox
                          v-model="subRole.isChecked"
                          class="ml-3 mt-0 pt-0"
                          hide-details
                          :disabled="accessEnableUpdate"
                          @change="
                            checkParentAction(subRole, subRole.isChecked)
                          "
                        ></v-checkbox>

                        {{ convertCamelCase(subRole.parentSection) }}
                      </v-list-item-title>
                    </template>
                  </v-list-item-content>

                  <v-list-item class="listItem">
                    <v-list-item-title
                      class="d-flex subClass"
                      v-for="(subRole, ind) in roles"
                      :key="ind"
                    >
                      <!-- <v-checkbox
                          v-model="subRole.isActionAssigned"
                          class="ml-3 mt-0 pt-0"
                          hide-details
                          :label="subRole.childSection"
                          @change="addAccess(subRole, $event)"
                        ></v-checkbox
                      > -->
                      <ThreeStateSwitch
                        :roleAccess="subRole"
                        @changeAccess="addAccess($event)"
                        :accessEnable="accessEnableUpdate"
                      />&nbsp;
                      <span
                        class="textEllipsis"
                        :title="convertCamelCase(subRole.childSection)"
                      >
                        {{ convertCamelCase(subRole.childSection) }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <div class="text-right">
                <v-btn
                  class="theme-btn-gold"
                  @click="updateRole"
                  :loading="loading"
                  :disabled="roleList.length <= 0"
                  >Change Access Setting</v-btn
                >
              </div>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <progress-circular :dialog="loading" />
  </master-layout>
</template>

<script>
import ThreeStateSwitch from "../components/ThreeStateSwitch.vue";
import ActionService from "../services/ActionService";
import UserService from "../services/UserService";
import { actionEnable } from "../utils/helpers";
import ProgressCircular from "../components/ProgressCircular.vue";
export default {
  data: () => ({
    checkbox: false,
    roleList: [],
    arrayOfAccess: [],
    rolename: 1,
    userId: "",
    userType: [],
    roles: [],
    loading: false,
    groupAction: [],
    updateGroupedArray: [],
    access: [],
    action: false,
  }),
  components: { ThreeStateSwitch, ProgressCircular },

  computed: {
    accessEnableUpdate() {
      return !actionEnable("UserAccess", "Update", 1);
    },
  },
  mounted() {
    // this.loading = true;
    // this.getrolesList();
    this.getUserType();
    this.getUserById();
    // this.loading = false;
  },

  methods: {
    async getUserType() {
      try {
        this.loading = true;
        const response = await UserService.getRolestype();
        this.roles = response.data.data;
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },

    async getUserById() {
      try {
        this.loading = true;
        const response = await ActionService.userListRole(this.rolename);
        this.userType = response.data.data;
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
      }
      this.loading = false;
    },

    async getrolesList() {
      try {
        this.loading = true;
        const res = await ActionService.roleFetchList({
          userId: this.userId,
          roleId: this.rolename,
        });

        this.groupAction = res.data.data.map((item) => {
          return item.accessModeId === 1
            ? { ...item, isChecked: true }
            : { ...item, isChecked: false };
        });

        // console.log(
        //   resultArray.groupBy((value) => {
        //     return value.parentSection;
        //   })
        // );

        this.getActionList();

        this.loading = false;
      } catch (error) {
        console.log(error);
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    addAccess(access) {
      let ind = this.groupAction.findIndex(
        (item) => access.actionId == item.actionId
      );
      this.groupAction[ind] = access;
      this.getActionList();
    },
    async updateRole() {
      try {
        const action = this.groupAction.map((item) => {
          return {
            accessModeId: item.accessModeId,
            actionId: item.actionId,
          };
        });
        this.loading = true;
        const response = await ActionService.updateRolesList({
          roleId: this.rolename,
          userId: this.userId,
          action: action,
        });
        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    // getUserList() {},

    getActionList() {
      this.roleList = this.groupAction.reduce((group, array) => {
        group[array.parentSection] = group[array.parentSection] || [];
        group[array.parentSection].push({ ...array });
        return group;
      }, {});
      for (const [key, value] of Object.entries(this.roleList)) {
        key;
        if (value.some((item) => item.isChecked === false) ? false : true) {
          value.forEach((val) => {
            val.isChecked = true;
          });
        } else {
          value.forEach((val) => {
            val.isChecked = false;
          });
        }
      }
    },
    checkParentAction(role, actionType) {
      this.groupAction.forEach((action, index) => {
        if (action.parentSection === role.parentSection) {
          if (actionType) {
            this.groupAction[index] = {
              ...action,
              accessModeId: 1,
              isChecked: true,
            };
          } else {
            this.groupAction[index] = {
              ...action,
              accessModeId: 0,
              isChecked: false,
            };
          }
        }
      });

      this.getActionList();
    },
    convertCamelCase(text) {
      let result = text.replace(/([A-Z])/g, " $1");
      let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
  },
};
</script>
<style scoped>
.subClass {
  width: fit-content;
  padding: 4px;
}
.listItem {
  align-items: center;
  display: block !important;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
.vCardClass {
  display: flex;
  flex-wrap: wrap;
}
.vListClass {
  border-radius: 0;
  min-width: 33.33%;
}
.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}
</style>
>
