import http from "./http-client";

class ActionService {
  roleFetchList(payload) {
    return http.get(
      `/UserAccess/List?roleId=${payload.roleId}&userId=${payload.userId}`
    );
  }
  updateRolesList(payload) {
    return http.post("/UserAccess/Update", payload);
  }
  userListRole(payload) {
    return http.get(`/UserAccess/UserListByRole?roleId=${payload}`);
  }
}

export default new ActionService();
